<template>
  <div class="wrapper">
    <admin-title title="充电站"></admin-title>
    <div class="station">
      <SelectStation v-model="station_id"></SelectStation>
      <el-button style="margin-left: 20px" type="primary" size="small" @click="goSearch">搜索</el-button>
    </div>
    <admin-title title="数据概况"></admin-title>
    <!-- 数据概况 -->
    <div class="data-count">
      <!-- 订单营业额 -->
      <el-row :gutter="10">
        <el-col :span="8">
          <div class="item-col" @click="goOrderList">
            <div class="item">
              <div class="left">
                <i class="el-icon-coin"></i>
              </div>
              <div class="right">
                <span class="num">
                  <div class="today">今日+{{ orderStatic?.orderMoneyStatic?.today?.todayChargeOrderMoney || 0 }}</div>
                  <div class="total">{{ orderStatic?.orderMoneyStatic?.all?.allChargeMoney || 0 }}</div>
                </span>
                <span class="title">总充电订单总营业额</span>
              </div>
            </div>
          </div>
        </el-col>
        <!-- <el-col :span="8">
          <div class="item-col" @click="goOrderList">
            <div class="item">
              <div class="left">
                <i class="el-icon-coin"></i>
              </div>
              <div class="right">
                <span class="num">
                  <div class="today">今日+{{ orderStatic?.orderMoneyStatic?.today?.todaySeatOrderMoney || 0 }}</div>
                  <div class="total">{{ orderStatic?.orderMoneyStatic?.all?.allSeatMoney || 0 }}</div>
                </span>
                <span class="title">总占位订单总营业额</span>
              </div>
            </div>
          </div>
        </el-col> -->
        <el-col :span="8">
          <div class="item-col" @click="goOrderList">
            <div class="item">
              <div class="left">
                <i class="el-icon-coin"></i>
              </div>
              <div class="right">
                <span class="num">
                  <div class="today">今日+{{ orderStatic?.orderMoneyStatic?.today?.allTodayOrderMoney || 0 }}</div>
                  <div class="total">{{ orderStatic?.orderMoneyStatic?.all?.allMoney || 0 }}</div>
                </span>
                <span class="title">总订单总额</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item-col" @click="goDataStatic">
            <div class="item">
              <div class="left">
                <i class="el-icon-box"></i>
              </div>
              <div class="right">
                <span class="num">
                  <div class="today">正常运行：{{ deviceStatic?.normalCount || 0 }}</div>
                  <div class="total">{{ deviceStatic?.allCount || 0 }}</div>
                </span>
                <span class="title">总设备数</span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- 订单数量 -->
      <el-row :gutter="10">
        <el-col :span="8">
          <div class="item-col" @click="goOrderList">
            <div class="item">
              <div class="left">
                <i class="el-icon-s-order"></i>
              </div>
              <div class="right">
                <span class="num">
                  <div class="today">今日+{{ orderStatic?.orderCountStatic?.today?.todayChargeCount || 0 }}</div>
                  <div class="total">
                    {{ orderStatic?.orderCountStatic?.all?.chargeCount || 0 }}
                  </div>
                </span>
                <span class="title">总充电订单数量</span>
              </div>
            </div>
          </div>
        </el-col>
        <!-- <el-col :span="8">
          <div class="item-col" @click="goOrderList">
            <div class="item">
              <div class="left">
                <i class="el-icon-s-order"></i>
              </div>
              <div class="right">
                <span class="num">
                  <div class="today">今日+{{ orderStatic?.orderCountStatic?.today?.todaySeatCount || 0 }}</div>
                  <div class="total">{{ orderStatic?.orderCountStatic?.all?.seatCount || 0 }}</div>
                </span>
                <span class="title">总占位订单数量</span>
              </div>
            </div>
          </div>
        </el-col> -->
        <el-col :span="8">
          <div class="item-col" @click="goOrderList">
            <div class="item">
              <div class="left">
                <i class="el-icon-s-order"></i>
              </div>
              <div class="right">
                <span class="num">
                  <div class="today">今日+{{ orderStatic?.orderCountStatic?.today?.allTodayCount || 0 }}</div>
                  <div class="total">{{ orderStatic?.orderCountStatic?.all?.allCount || 0 }}</div>
                </span>
                <span class="title">订单总数量</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item-col" @click="goDataStatic">
            <div class="item">
              <div class="left">
                <i class="el-icon-s-data"></i>
              </div>
              <div class="right">
                <span class="num">
                  <div class="today">今日+{{ statKWS.today || 0 }}</div>
                  <div class="total">{{ statKWS.all || 0 }}</div>
                </span>
                <span class="title">总用电量</span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- 用电量、设备 -->
      <el-row :gutter="10"> </el-row>
    </div>
    <!-- 15天数据 -->
    <admin-title title="近15天数据趋势"></admin-title>
    <!-- 订单数量、金额 -->
    <!-- <div class="order-chart">
      <LineChartMult
        title="近15日订单趋势"
        :list_x="order_list_x"
        :list_y1="order_count_y"
        :list_y2="order_money_y"
        :legend="['数量', '金额']"
      ></LineChartMult>
    </div> -->
    <div class="user-chart">
      <LineChart title="近15天订单统计" :list_x="order_list_x" :list_y="order_list_y"></LineChart>
    </div>
    <!-- 用户统计 -->
    <div class="user-chart" v-if="countUserByDayList.length > 0">
      <LineChart title="近15天用户统计" :list_x="user_list_x" :list_y="user_list_y"></LineChart>
    </div>
    <!-- 电费 -->
    <!-- <div class="charge-chart">
      <LineChart title="近15天电费统计" :list_x="charge_list_x" :list_y="charge_list_y"></LineChart>
    </div> -->
    <!-- 设备 -->
    <!-- <div class="device-chart">
      <BarChart title="近15天设备运行状态" :list_x="device_list_x" :series="device_series" :legend="['正常设备', '异常设备', '总数量']"></BarChart>
    </div> -->
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { getDataStaticAPI, getChartStatAPI } from './api'
import SelectStation from '@/views/components/select-station.vue'
import LineChartMult from './charts/line-chart-mult.vue'
import LineChart from './charts/line-chart.vue'
import BarChart from './charts/bar-chart.vue'
export default {
  name: 'Index',
  components: { SelectStation, LineChartMult, LineChart, BarChart },
  data() {
    return {
      station_id: '',
      orderStatic: {},
      deviceStatic: {},
      staticOrderByDayList: [], //近15日的订单的
      staticOrderMoneyByDayList: [], //近15日的订单金额
      countUserByDayList: [], //近15填用户统计
      chargeLevelByDayList: [], //近15天电费
      staticDeviceByDayList: [], //近15天设备运行状态
      statKWS: {} //总用电量
    }
  },

  mounted() {
    this.getDataStatic()
    this.getChartStat()
  },
  computed: {
    order_list_x() {
      return this.staticOrderByDayList.map((item) => item.day) || []
    },
    order_list_y() {
      return this.staticOrderByDayList.map((item) => item.count) || []
    },
    // order_money_y() {
    //   return this.staticOrderMoneyByDayList.map((item) => item.order_money) || []
    // },
    user_list_x() {
      return this.countUserByDayList.map((item) => item.day)
    },
    user_list_y() {
      return this.countUserByDayList.map((item) => item.count)
    }
    // charge_list_x() {
    //   return this.chargeLevelByDayList.map((item) => item.order_date)
    // },
    // charge_list_y() {
    //   return this.chargeLevelByDayList.map((item) => item.charge_level)
    // },
    // device_list_x() {
    //   return this.staticDeviceByDayList.map((item) => item.create_day)
    // },
    // device_series() {
    //   const normal_count = {
    //     name: '正常设备',
    //     type: 'bar',
    //     data: this.staticDeviceByDayList.map((item) => {
    //       return {
    //         value: item.normal_count,
    //         itemStyle: {
    //           // color: '#a90000'
    //         }
    //       }
    //     })
    //   }
    //   const abnormal_count = {
    //     name: '异常设备',
    //     type: 'bar',
    //     // barWidth: '10%',
    //     data: this.staticDeviceByDayList.map((item) => {
    //       return {
    //         value: item.abnormal_count,
    //         itemStyle: {
    //           // color: '#409EFF'
    //         }
    //       }
    //     })
    //   }
    //   const all_count = {
    //     name: '总数量',
    //     type: 'bar',
    //     // barWidth: '10%',
    //     data: this.staticDeviceByDayList.map((item) => {
    //       return {
    //         value: item.all_count,
    //         itemStyle: {
    //           // color: '#67C23A'
    //         }
    //       }
    //     })
    //   }
    //   return [normal_count, abnormal_count, all_count]
    // }
  },

  methods: {
    goSearch() {
      this.getChartStat()
      this.getDataStatic()
    },
    async getChartStat() {
      const res = await getChartStatAPI(this.station_id)
      this.staticOrderByDayList = res.order_stat
      this.countUserByDayList = res.user_stat
    },
    async getDataStatic() {
      const res = await getDataStaticAPI(this.station_id)
      this.orderStatic = res.orderStatic
      this.deviceStatic = res.deviceStatic
      // this.staticOrderByDayList = res.staticOrderByDayList
      // this.staticOrderMoneyByDayList = res.staticOrderMoneyByDayList
      // this.countUserByDayList = res.countUserByDayList
      // this.chargeLevelByDayList = res.chargeLevelByDayList
      // this.staticDeviceByDayList = res.staticDeviceByDayList
      this.statKWS = res.statKWS
    },
    // 订单营业额
    goOrderList() {
      this.$router.push({ name: 'PayList' })
    },
    goDataStatic() {
      // this.$router.push({ name: 'DataStatic' })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .station {
    margin-bottom: 20px;
  }
  .data-count {
    .el-row {
      .el-col {
        .item-col {
          cursor: pointer;
          display: flex;
          justify-content: center;
          padding: 20px 0;
          &:hover {
            background-color: #f0f2f5;
            border-radius: 10px;
          }
          .item {
            display: flex;
            align-items: center;
            width: 250px;
            .left {
              background-color: #f0f8fe;
              border-radius: 50%;
              padding: 15px;
              i {
                font-size: 38px;
                color: #6ea6eb;
              }
            }
            .right {
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-around;
              margin-left: 20px;
              .num {
                flex: 1;
                position: relative;
                .today {
                  position: absolute;
                  top: 0px;
                  right: 0px;
                  background-color: #409eff;
                  border-radius: 10px;
                  padding: 0 10px;
                  color: #fff;
                  white-space: nowrap;
                  transform: translateY(-20%) translateX(95%);
                  font-size: 14px;
                }
                .total {
                  margin-top: 10px;
                  font-size: 24px;
                  font-weight: 600;
                }
              }
              .title {
                font-weight: 600;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .order-chart,
  .user-chart,
  .charge-chart,
  .device-chart {
    height: 350px;
  }
}
</style>
